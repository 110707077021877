import logo from './logo.svg';
import './App.css';
import './index.css';
import SignUpContainer from './components/SignUpContainer';
import React, { useEffect, useState } from 'react';

function App() {
  const [state, setState] = useState({ isModal: false });
  useEffect(() => {
    isModal();
  }, [])
  const isModal = () => {
    let params = (new URL(document.location)).searchParams;
    let isModal = params.get("isModal");
    // console.log(isModal);
    if(isModal) {
      console.log('it is a modal')
      setState({ ...state, isModal: true });
    }
  }

  return (
    <div className="App">
      <div className={state.isModal ? 'md:h-48 text-center' : 'background h-96 text-center'}>
        {!state.isModal && 
      <h1 className="text-center text-4xl text-white font-bold pt-16">Join us on an epic adventure!</h1>
    }
      </div>
      <SignUpContainer />
    </div>
  );
}


export default App;
