import React, { useEffect, useState } from 'react';
const SignUpContainer = () => {

  const [state, setState] = useState({ result: '' });

  const handleSubmit = () => {
    fetch('https://tingutravel.com/api/voucher/generate', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: state.email, first_name: state.first_name, phone_number: btoa(state.phone_number) })
    }).then(res => res.json())
      .then(res => setState({ ...state, isLoaded: true, result: res }));
  }

  useEffect(() => {
    isModal();
  }, [])

  var statusBlock;

  const handleEmailChange = (e) => {
    // console.log(e.target.value);
    setState({ ...state, email: e.target.value });
  }

  const handleNameChange = (e) => {
    // console.log(e.target.value);
    setState({ ...state, first_name: e.target.value });
  }


  const handlePhoneNumberChange = (e) => {
    // console.log(e.target.value);
    setState({ ...state, phone_number: e.target.value });
  }

  const isModal = () => {
    let params = (new URL(document.location)).searchParams;
    let isModal = params.get("isModal");
    if(isModal) {
      setState({ ...state, isModal: true });
    }
  }
  return (
    <div className="relative">
      <div className="bg-white shadow rounded-lg md:absolute md:-top-32 md:left-0 md:bottom-0 mx-auto md:right-0 md:w-96 w-full">
        <div className="flex">
          <div className="w-full px-4 pt-4 bg-white ">
            <form action="http://localhost:3002/api/voucher/generate" method="POST">
              <h2 className="font-bold text-xl">Join our mailing list and get 5% off a trip!</h2>
              {state.isLoaded ?
                state.result.error ? <div className="mt-4 bg-red-900 text-white p-4 rounded-md">{state.result.value}</div> : <div className="mt-4 bg-green-900 text-white p-4 rounded-md">{state.result.value}</div>
                : ''}


              <div className="mt-4">
                <label for="first_name" className="block text-sm font-medium text-gray-700">First name</label>
                <div className="mt-1">
                  <input type="text" onChange={handleNameChange} value={state.first_name} name="first_name" id="first_name" className="p-1 w-full border-gray-800 border-2 rounded-sm" placeholder="James" />
                </div>
              </div>
              <div className="mt-4">
                <label for="user_email" className="block text-sm font-medium text-gray-700">Email</label>
                <div className="mt-1">
                  <input type="text" onChange={handleEmailChange} value={state.email} name="user_email" id="user_email" className="p-1 w-full border-gray-800 border-2 rounded-sm" placeholder="you@example.com" />
                </div>
              </div>
              <div className="mt-4">
                <label for="phone_number" className="block text-sm font-medium text-gray-700">WhatsApp Number</label>
                <div className="mt-1">
                  <input type="text" onChange={handlePhoneNumberChange} value={state.phone_number} name="phone_number" id="phone_number" className="p-1 w-full border-gray-800 border-2 rounded-sm" placeholder="+9715400239" />
                </div>
              </div>
              <div className="my-8">
                <button type="button" onClick={() => handleSubmit()} class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Sign up
</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpContainer;
